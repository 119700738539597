@import './assets/css/style.css';
@import './assets/css/feathericon.min.css';
@import './assets/css/style.css';
.loader{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.2);
    z-index: 100;
}

