.dropdown button{
    background: transparent;
    border-color: transparent;

}
.dropdown .dropdown{
    padding: 7px 0;
}
.dropdown button:hover,.dropdown button:focus,.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
    background: transparent !important;
    border-color: transparent !important;
}
.dropdown .dropdown-menu a{
    cursor: pointer;
}
.noti_info{
    position:absolute;
    top: 15px;
    right: 20px;
}
.noti_box{
    position: relative;
}